import { Component } from '@angular/core';
import { Job, JobShortDisplay } from 'src/app/models/jobs.model';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})

export class JobsComponent {
  jobs: Job[] = [
    {
      id: 1,
      title: 'Software Engineer',
      description: 'Exciting software engineering position focusing on building scalable web applications.',
      details: [
        'This role involves working with a dynamic team.',
        'You will be building and maintaining high-performance systems.'
      ],
      requirements: [
        'Bachelor’s degree in Computer Science or related field.',
        '3+ years of experience in web development.'
      ],
      responsibilities: [
        'Design, develop, and maintain web applications.',
        'Collaborate with cross-functional teams.'
      ]
      
    },
    {
      id: 2,
      title: 'Cleaning Specialist',
      description: 'We are looking for a dedicated and meticulous Cleaner to join our team. The ideal candidate will be passionate about creating a welcoming environment through good hygiene. We’re looking for a cleaning specialist who will exceed client expectations.',
      details: [
        'This role involves working with a dynamic team.',
        'You will be building and maintaining high-performance systems.'
      ],
      requirements: [
        'Proven experience as a cleaner or housekeeper.',
        'Ability to work with little supervision and maintain a high level of performance.',
        'Customer-oriented and friendly.',
        'Prioritization and time management skills.',
        'Ability to aid team members in lifting 50 or more pounds.',
        'Ability to stand for long period of time.',
        'Ability to reach, bend stoop and kneel.'

      ],
      responsibilities: [
        'Dusting, sweeping, mopping, and washing floors, toilets, showers, tubs, windows, and counters.',
        'Vacuuming carpets, upholstery, and any other dusty surface',
        'Cleaning all surfaces in the kitchen and bathroom.',
        'Making beds and fluffing pillows.',
        'Handling all furniture and appliances carefully.',
        'Cleaning blinds and windows.',
        'Washing blinds.',
        'Reporting any necessary repairs or replacements.',
        'Cleaning mirrors and other glass surfaces.',
        'Emptying trash receptacles and disposing of waste.',
        'Steaming and cleaning draperies.',
        'Washing and folding laundry.',
        'Scrubbing sinks, basins, and toilets in private and public bathrooms.',
        'Cleaning carpets.',
        'Sweeping driveways and walkways.',
        
      ],
      certifications: [
        'High School Diploma or equivalent.',
        'Valid driver’s license/ ID.',
        'Ability to pass a background check.',
        'Must Meet valid Insurance underwriting Requirements.',
      ]
      
    },
  ];

  jobsShortDisplay: JobShortDisplay[] = [
    
    {
      id: 1,
      title: 'Cleaning Specialist',
      description: 'We are looking for a dedicated and meticulous Cleaner to join our team. The ideal candidate will be passionate about creating a welcoming environment through good hygiene. We’re looking for a cleaning specialist who will exceed client expectations.',
      
      
    },
    {
      id: 2,
      title: ' Customer Service Representative',
      description: 'We are currently seeking a Customer Service Representative who is passionate about providing outstanding customer service and support. In this role, you will be the first point of contact for our clients, addressing their inquiries, providing information about our services, and ensuring a smooth and satisfying customer experience.',     
      
    },
    {
      id: 3,
      title: 'Digital Marketing Specialist',
      description: 'We are seeking a skilled Digital Marketing Specialist to join our team. The ideal candidate will be responsible for developing, implementing, and managing marketing campaigns that promote our company and its products or services. As a Digital Marketing Specialist, you will play a pivotal role in enhancing brand awareness within the digital space, driving website traffic, and acquiring leads/customers.',
     
      
    },
  ]
}
