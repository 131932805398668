<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Unmatched Elegance</h1>
                            <p>Our cleaning services are infused with a sense of elegance, transforming your living spaces into opulent retreats. Experience the difference as we blend luxury with functionality for a home that radiates sophistication.</p>
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn active-btn">Hire Us!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Precision and Detail</h1>
                            <p>Our team comprises highly skilled and professional cleaners dedicated to upholding the highest standards of service.</p>
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn ">Know More</a>
                                <a routerLink="/contact" class="default-btn active-btn">Hire Us!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Opulent Customer Experience</h1>
                            <p>Our customer-centric approach ensures your satisfaction at every step;ensuring your home is treated with the respect and care it deserves.</p>
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn ">Know More</a>
                                <a routerLink="/contact" class="default-btn active-btn">Hire Us!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Quality Cleaning <span>Services</span></h2>
            <p>Opulent Detail – because your home deserves nothing but the finest.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-award"></i>
                    <h3>High Quality</h3>
                    <p>Punctuality, courtesy, and a keen eye for detail are the cornerstones of our service.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-credit-card"></i>
                    <h3>Cost Effective</h3>
                    <p>Recognizing that every home is unique, we offer tailored solutions that align with your lifestyle.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clock"></i>
                    <h3>Fast Service</h3>
                    <p>We show up exactly on the scheduled time, and keep you informed.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="service-shapes">
            <img src="assets/img/service/shapes/1.png" alt="shape">
            <img src="assets/img/service/shapes/2.png" alt="shape">
            <img src="assets/img/service/shapes/3.png" alt="shape">
            <img src="assets/img/service/shapes/4.png" alt="shape">
            <img src="assets/img/service/shapes/5.png" alt="shape">
            <img src="assets/img/service/shapes/6.png" alt="shape">
            <img src="assets/img/service/shapes/7.png" alt="shape">
            <img src="assets/img/service/shapes/8.png" alt="shape">
            <img src="assets/img/service/shapes/9.png" alt="shape">
            <img src="assets/img/service/shapes/10.png" alt="shape">
        </div>
    </div>
</section>

<section class="why-choose-section why-choose-bg">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 why-choose-img"></div>

            <div class="col-lg-5 offset-lg-6 offset-md-0">
                <div class="why-choose-text">
                    <div class="section-head">
                        <h2>Why Choose Us</h2>
                        <p>We believe that our commitment to do a good job sets us apart. We want to leave your home clean and shiny!</p>
                    </div>
                </div>
                <div class="why-choose-accordian">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <a href="#" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Expert Cleaners
                                    </a>
                                </h2>
                            </div>                           
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Our skilled professionals can handle a variety of cleaning challenges with precision and care.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Eco-Friendly
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Embrace environmentally conscious cleaning practices without compromising on luxury.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Clean & Fast Service
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Our Clean & Fast Service is designed for those who lead busy lives. Experience a rapid turnaround without compromising on the quality of the cleaning.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Customized Cleaning Solution
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Enjoy the flexibility of personalized cleaning plans tailored to your unique requirements.
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="why-choose-contact">
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your Number">
                            </div>
                            <button type="submit" class="btn btn-primary">Request A Call</button>
                        </form>
                        <p>Get a free call for service</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="why-choose-shape">
            <img src="assets/img/why-choose/shape-1.png" alt="shape">
        </div>
    </div>
</section>

<div class="process-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Cleaning plans tailored to your home and schedule. </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Book Online</h3>
                    <p>Get your house cleaned and book us Fast!</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>We thrive on your feedback, we will take care of your home!</p>
                </div>
            </div> 
        </div>
    </div>
</div>