import { Component } from '@angular/core';

@Component({
  selector: 'app-bookingsystem',
  standalone:true,
  templateUrl: './bookingsystem.component.html',
  styleUrls: ['./bookingsystem.component.scss']
})
export class BookingsystemComponent {
  selectedRooms: number;
  selectedServices: string[] = [];
  extraServices: string[] = ['Carpet Cleaning', 'Window Washing', 'Deep Cleaning'];

  selectedDate: string;
  selectedTime: string;

  toggleService(service: string): void {
    const index = this.selectedServices.indexOf(service);
    if (index === -1) {
      this.selectedServices.push(service);
    } else {
      this.selectedServices.splice(index, 1);
    }
  }

  submitBooking(): void {
    // Handle the booking submission logic here
    console.log('Booking submitted:', {
      rooms: this.selectedRooms,
      services: this.selectedServices,
      date: this.selectedDate,
      time: this.selectedTime,
    });
  }
}
