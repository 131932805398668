<!-- <div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/contact-img.png" alt="contact image">      
                    </div>           
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>
                                
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div> -->

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo-2.png" alt="logo">
                        </a>
                    </div>
                    <p>Hire Us Now! and get 15% Discount .</p>

                    <!-- <div class="email">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Services</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Low Cost</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Fastest Service</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Professional Cleaning</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Home Cleaning</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Office Cleaning</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Home</a></li>                       
                        <li><i class="icofont-simple-right"></i> <a routerLink="/jobs">Careers</a></li>                      
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Opulent Living</h3>
                    <p class="find-text">Ready to experience the epitome of cleanliness and luxury? Contact Us today to schedule your home cleaning service</p>

                    <ul class="find-us">
                        <li>                                   
                            <i class="icofont-location-pin"></i> 1401 Elm St Dallas TX 75202   
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+880123456789">+88 0123 456 789</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:hello@opulentdetail.com">hello@opulentdetail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>             
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>© Opulent Detail <a href="https://hibootstrap.com/" target="_blank">2024</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>