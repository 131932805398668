<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Help</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">            
                <h4 >Frequently Asked Questions</h4>
                <div class="accordion" id="accordionExample">
                    <div *ngFor="let faq of faqList;index as i" class="accordion-item mb-1">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target='#collapseOne{{i}}' aria-expanded="false" attr.aria-controls="collapseOne{{i}}">
                              {{faq.question}}
                            </button>
                          </h2>
                          <div id="collapseOne{{i}}" class="accordion-collapse collapse"  data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {{ faq.answer }}
                            </div>
                          </div>
                    
                    </div>
                  </div>
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div>         
                    <br>      
                    
                </div>
            </div>
        </div>
    </div>
</div>

  