import { Component } from '@angular/core';
@Component({
  selector: 'app-faq', 
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
 
})
export class FaqComponent {
  
  faqList = [
    { question: 'What services do you offer?', answer: 'We offer a range of services, including deep cleaning, routine cleaning, and specialized cleaning for homes and offices.' },
    { question: 'How can I schedule a cleaning?', answer: 'Scheduling a cleaning is easy! You can contact us through our website or give us a call, and we\'ll find a time that works best for you.' },
    { question: 'Are your cleaning products safe for pets and children?', answer: 'Absolutely! We use environmentally friendly and non-toxic cleaning products to ensure the safety of your loved ones, including pets and children.' },
    { question: 'Do I need to be home during the cleaning?', answer: 'It\'s entirely up to you. Many of our clients prefer to be away while we work, but if you\'d like to be there, we\'re comfortable working around your schedule.' },
    { question: 'What areas do you serve?', answer: 'We proudly serve [Your City/Area] and the surrounding areas. If you\'re unsure whether we cover your location, just reach out, and we\'ll be happy to assist you.' },
    { question: 'How do you ensure the security of my home or office?', answer: 'We take security seriously. Our team members undergo thorough background checks, and we prioritize the confidentiality and security of your space.' },
    // Add more FAQs as needed
  ];

  
}