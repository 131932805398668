<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Mon - Fri : 9:00AM - 05:00PM</li>
                        <li><i class="icofont-location-pin"></i> Dallas Fort-Worth</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+880123456789">+88 0123 456 789</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-twitter"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-linkedin"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" width="300px;" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/service" class="nav-link"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >Services</a></li>
                        <li class="nav-item"><a routerLink="/help" class="nav-link"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Help</a></li>                       
                        <!-- <li class="nav-item"><a routerLink="/jobs" class="nav-link"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"  >Jobs</a></li> -->
                    </ul>

                    <div class="navbar-button">
                        <a routerLink="/booking" class="text-nowrap" style="display:inline-block;">Book Now</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>