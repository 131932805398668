<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">            
                <div class="service-details-post">
                    <h3>Cleaning Services</h3>
                    <p>we take pride in offering a unique and dynamic cleaning experience that seamlessly combines cleanliness and speed. Here's why choosing our Clean & Fast Service is the smart choice for those who value efficiency:</p>

                    <p>Choose Opulent Detail for a cleaning service that transcends traditional standards, combining opulence, precision, and professionalism. Elevate your expectations, and let us redefine what it means to experience luxury in every aspect of home cleaning. Opulent Detail – where elegance meets cleanliness.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Kitchen Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Floor cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Balcony
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Window Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Bathroom
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Door & Windows Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Cabinets Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Fridge Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Oven Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Load of Laundry
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                           Disinfecting
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Hand Wash Dishes
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Move In/Move Out
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Deep Cleaning
                        </li>
                    </ul>

                    <p>Our mission is to transform your living spaces into havens of opulence by providing unparalleled cleaning services that go beyond the ordinary.</p>
                        
                    <p>We get that everyone's schedule is different. Our cleaning services are designed to fit seamlessly into your routine, whether you're a busy professional, a parent juggling a thousand things, or just someone who values their free time.</p>
     
                    <p>Cleaning shouldn't be a headache. With our services, it's as straightforward as it gets. Quick response times, efficient teams, and no unnecessary delays – just the clean space you want without the stress.</p>
                    
                    <p>Ready to experience hassle-free, quick, and quality cleaning? Reach out and book us today!</p>
                    <div class="theme-button">
                        <a routerLink="/pricing" class="default-btn">Book Now</a>
                    </div>
                </div>

                <div class="page-button">
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    <i class="icofont-rounded-left"></i>
                                    Previous
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 offset-md-6">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    Next
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div>         
                    <br>      
                    <div class="service-list">
                        <p>What we clean</p>                            
                        <ul>
                            <li>
                                <a routerLink="/">
                                    Toilet Cleaning 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Stay Hygienic 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    News
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Cleaning Floor
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Cleaning Kitchen
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Wash Home
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>