import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BookingsystemComponent } from './components/bookingsystem/bookingsystem.component';
const routes: Routes = [
    {path: '', component: HomeOneComponent},    
    {path: 'about', component: AboutComponent},
    {path: 'service', component: ServiceComponent},    
    {path: 'help', component: FaqComponent},
    {path: 'error', component: ErrorComponent},    
    {path: 'jobs', component: JobsComponent},
    {path: 'booking', component: BookingsystemComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }