<!-- booking.component.html -->
<div class="booking-container">
    <h2>Book a Cleaning Service</h2>
  
    <!-- Number of Rooms -->
    <label for="rooms">Number of Rooms:</label>
    <input type="number" id="rooms"  />
  
    <!-- Extra Services -->
    <div>
      <h4>Extra Services:</h4>
      <button
        *ngFor="let service of extraServices"
        (click)="toggleService(service)"
        [class.selected]="selectedServices.includes(service)"
      >
        {{ service }}
      </button>
    </div>
  
    <!-- Date and Time -->
    <label for="date">Date:</label>
    <input type="date" id="date"  />
    <label for="time">Time:</label>
    <input type="time" id="time"  />
  
    <!-- Submit Button -->
    <button (click)="submitBooking()">Book Now</button>
  </div>
  
